$(document).ready(function() {


  // jQuery for page scrolling feature - requires jQuery Easing plugin
  $(document).on('click', 'a.ss', function(event) {
      var $anchor = $(this);
      $('html, body').stop().animate({
          scrollTop: ($($anchor.attr('href')).offset().top - 132)
      }, 1250, 'easeInOutExpo');
      event.preventDefault();
  });




  var form = $('#registerForm'),
      name = $('#registerName'),
      email = $('#registerEmail'),
      phone = $('#registerPhone'),
      result = $('#registerResult');

  form.on('input', '#registerName, #registerEmail, #registerPhone', function() {
    $(this).css('border-color', '');
    result.html('').slideUp();
  });

  form.submit(function(e) {
    e.preventDefault();
    result.removeClass('success').html('').slideUp();
    if(validate()) {
      $.ajax({
        type: form.attr('method'),
        url: form.attr('action'),
        data: form.serialize(),
        dataType: 'json'
      }).done(function(response) {
        console.log(response);
        if(response.result) {
          name.val('');
          email.val('');
          phone.val('');
          result.addClass('success').html('Thank you for registering your interest.').slideDown();
        } else {
          result.html('Please try again.').css('color', '#bb0000').slideDown();
        }
      });
    }
  });

  function validate() {
    var valid = true;
    var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    var res = '';

    if($.trim(name.val()) === '') {
      name.css('border-color', '#bb0000');
      valid = false;
      res += 'Name';
    }
    if(!regex.test(email.val())) {
      email.css('border-color', '#bb0000');
      valid = false;
      if(res.length > 0) {
        res += ', ';
      }
      res += 'Email';
    }
    if($.trim(phone.val()) === '') {
      phone.css('border-color', '#bb0000');
      valid = false;
      if(res.length > 0) {
        res += ', ';
      }
      res += 'Phone';
    }

    if(!valid) {
      result.html('Please enter your ' + res).css('color', '#bb0000').slideDown();
    }
    return valid;
  }


  $(document).on('click', '[data-toggle="lightbox"]', function(event) {
    event.preventDefault();
    $(this).ekkoLightbox();
  });
});
